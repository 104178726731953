export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'FinancesInvoices',
            title: 'Счета',
            hash: ''
        },
        {
            id: 2,
            name: 'FinancesPayings',
            title: 'Оплаты',
            hash: ''
        },
        {
            id: 3,
            name: 'FinancesElementsService',
            title: 'НВУ',
            hash: ''
        }
    ]
}


