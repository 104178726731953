<template>
    <div>
        <vue-title title="Оплата | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Оплата {{paying ? paying.number : ''}}</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!balanceAccountId">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="balanceAccountId">
                        <Paying
                                :payingId="payingId"
                                :agent="Agent"
                                :balanceAccountId="balanceAccountId"
                                :getPaying="getPaying"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {Header, Footer} from '../../shared/components';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';

    import Paying from './detail/Paying';

    export default {
        name: 'FinancesPaying',
        components: {
            Header,
            Footer,
            DxLoadIndicator,
            Paying,
        },
        data() {
            return {
                headerRoutes,
                payingId: this.$route.params.payingId,
                paying: null,
                balanceAccountId: "",
            }
        },
        mounted() {
            let account = localStorage.getItem('account');
            if (account) {
                account = JSON.parse(account);

                this.balanceAccountId = account.balanceAccountId;
            }
        },
        methods: {
            getPaying(paying) {
                this.paying = paying;
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
