<template>
    <div>
        <div v-if="!paying">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="paying" class="table-responsive-md">
            <div class="row select-invoice">
                <div class="col-12 col-md-6 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Номер:
                    </p>
                    <p class="item-text">
                        {{ paying.number }}
                    </p>
                </div>
                <div class="col-12 col-md-6 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Дата:
                    </p>
                    <p class="item-text">
                        {{ paying.payingDate | moment("DD.MM.YYYY") }}
                    </p>
                </div>
                <div class="col-12 col-md-8 col-lg-6 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Договор:
                    </p>
                    <p class="item-text">
                        <button :id="'btn-contract-caption-'+paying.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openContract(paying.contractId)">{{ paying.contractCaption }}</button>
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2 mb-0">
                        Состояние:
                    </p>
                    <p class="item-text" v-html="getStatus(paying.status)"></p>
                </div>
                <div class="col-12 col-lg-6 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Первичный документ:
                    </p>
                    <p class="item-text" v-if="paying.primaryDocumentCaption">
                        <button :id="'btn-primaryDocument-caption-'+paying.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(paying.primaryDocumentId)">{{ paying.primaryDocumentCaption }}</button>
                    </p>
                    <p class="item-text" v-if="!paying.primaryDocumentCaption">
                        -
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Сумма:
                    </p>
                    <p class="item-text">
                        {{ paying.amount | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Не разнесено:
                    </p>
                    <p class="item-text">
                        {{ paying.balanceAmount | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Разнесено:
                    </p>
                    <p class="item-text">
                        {{ paying.postingAmount | formatMoney2 }}
                    </p>
                </div>
            </div>
            <div>
                <ul class="nav nav-tabs" :id="'payingTab-'+paying.payingId" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" :id="'postings-tab-'+paying.payingId" data-toggle="tab" :href="'#postings-'+paying.payingId" role="tab" :aria-controls="'postings-'+paying.payingId" aria-selected="true">Проводки разноски платежа</a>
                    </li>
                </ul>
                <div class="tab-content" :id="'payingTabContent-'+paying.payingId">
                    <div class="tab-pane fade show active" :id="'postings-'+paying.payingId" role="tabpanel" :aria-labelledby="'postings-tab-'+paying.payingId">
                        <DxDataGrid
                                id="gridContainerPostings"
                                :data-source="paying.postings"
                                :columns="columnsPostings"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :show-row-lines="true"
                                :customize-columns="customizeColumnsPostings"
                        >
                            <template #invoice-caption-template="{ data }">
                                <button :id="'btn-invoice-caption-'+data.data.invoiceId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="openInvoice(data.data)">{{ data.data.invoiceCaption }}</button>
                            </template>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import localStore from '../store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import { DxDataGrid } from 'devextreme-vue/data-grid';

    export default {
        name: "Paying",
        props: ['payingId', 'Agent', 'balanceAccountId', 'getPaying'],
        components: {
            DxLoadIndicator,
            DxDataGrid
        },
        data() {
            return {
                paying: null,
                columnsPostings: [
                    {
                        caption: 'Счет',
                        dataField: 'invoiceCaption',
                        allowSorting: false,
                        cellTemplate: "invoice-caption-template",
                    },
                    {
                        caption: 'Сумма проводки',
                        dataField: 'amount',
                        width: 130,
                        allowSorting: false,
                        customizeText: this.formatMoney2
                    }
                ]
            }
        },
        mounted() {
            localStore.dispatch('getPaying', {tokenId: this.Agent.tokenId, balanceAccountId: this.balanceAccountId, payingId: this.payingId}).then(() => {
                this.paying = localStore.state.paying;

                if(this.getPaying) {
                    this.getPaying(this.paying);
                }
            })
        },
        methods: {
            customizeColumnsPostings(columns) {
                if(columns.length > 0) {
                    // if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                    //     columns[0].hidingPriority = 5;
                    //     columns[1].hidingPriority = 4;
                    //     columns[2].hidingPriority = 2;
                    //     columns[3].hidingPriority = 3;
                    //     columns[4].hidingPriority = 1;
                    // } else {
                    //     columns[0].hidingPriority = null;
                    //     columns[1].hidingPriority = null;
                    //     columns[2].hidingPriority = null;
                    //     columns[3].hidingPriority = null;
                    //     columns[4].hidingPriority = null;
                    // }
                }
            },
            formatMoney2(options) {
                return this.$options.filters.formatMoney2(options.value);
            },
            getStatus(status) {
                switch (status) {
                    case 'pdsEditing':
                        return '<span class="badge badge-warning font-12">В обработке</span>';

                    case 'pdsAccounted':
                        return '<span class="badge badge-success font-12">Обработан</span>';

                    case 'pdsReject':
                        return '<span class="badge badge-danger font-12">Анулирован</span>';
                }

                return '';
            },
            openContract(contractId) {
                let route = this.$router.resolve({name: 'ProfileContract', params: {contractId: contractId}});
                window.open(route.href, '_blank');
            },
            openInvoice(data) {
                let route = this.$router.resolve({name: 'FinancesInvoice', params: {invoiceId: data.invoiceId}});
                window.open(route.href, '_blank');
            },
            openPrimaryDocument(primaryDocumentId) {
                let route = this.$router.resolve({name: 'PrimaryDocsPrimaryDocument', params: {primaryDocumentId: primaryDocumentId}});
                window.open(route.href, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-invoice {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
