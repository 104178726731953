import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        accounts: null,
        account: null,
        accountShow: false,
        invoice: null,
        paying: null
    },
    mutations: {
        setAccounts(state, data) {
            state.accounts = data;
        },
        setAccount(state, data) {
            state.account = data;
            localStorage.setItem('account', JSON.stringify(data));
        },
        setAccountShow(state, data) {
            state.accountShow = data;
        },
        setInvoice(state, data) {
            state.invoice = data;
        },
        setPaying(state, data) {
            state.paying = data;
        }
    },
    actions: {
        getAccounts({commit}, {tokenId}) {
            commit('setAccounts', null);

            return httpClient
                .get(`v2/Finances/Accounts?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setAccounts', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getAccount({commit}, account) {
            commit('setAccount', account);
            return account;
        },
        getAccountShow({commit}, accountShow) {
            commit('setAccountShow', accountShow);
            return accountShow;
        },
        getInvoice({commit}, {tokenId, balanceAccountId, invoiceId}) {
            commit('setInvoice', null);

            return httpClient
                .get(`v2/Finances/${balanceAccountId}/Invoices/${invoiceId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setInvoice', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getPaying({commit}, {tokenId, balanceAccountId, payingId}) {
            commit('setPaying', null);

            return httpClient
                .get(`v2/Finances/${balanceAccountId}/Payings/${payingId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setPaying', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        }
    },
    getters: {},
    modules: {}
});
